<template>
    <div>
        <div class="card-tail flex justify-end items-center space-x-2 border-b">
            <el-checkbox v-model="includeCompleted" @change="updateFilters">Inkludera avslutade</el-checkbox>
            <div class="w-[200px]">
                <DateInputField ref="dateInputField" @change="inputChanged" placeholder="Slutdatum" :pre-selected-value="endDate" />
            </div>
            <div>
                <el-button type="warning" @click="clearFilters" class="py-[6px] px-[9px]">
                    <i class="fas fa-trash" />
                </el-button>
            </div>
        </div>
        <div class="card-tail flex items-center space-x-2 border-b">
            <el-radio-group v-model="interimAccount" @change="accountUpdated" class="mb-4">
                <el-radio :label="null" :key="0">{{ "Alla" }}</el-radio>
                <el-radio v-for="account in interimAccounts" :label="account.number" :key="account.number">{{ account.number + " - " + account.name }}</el-radio>
            </el-radio-group>
        </div>
    </div>
</template>
<script>
import queryString from "query-string";
import Api from "./lopande_accrual.api";

export default {
    data() {
        return {
            endDate: "",
            includeCompleted: false,
            interimAccount: null,
            interimAccounts: "",
        };
    },

    props: {
        clientId: {
            type: String,
            default: null,
        },
    },

    components: {
        DateInputField: () => import(/* webpackChunkName: "DateInputField" */ "@/components/DateInputField.vue"),
    },

    async created() {
        this.assignInitValues();
        this.interimAccounts = await Api.getInterimAccounts(this.clientId, this.endDate, this.includeCompleted, null, null);
    },

    methods: {
        accountUpdated() {
            this.updateFilters();
            console.log("🚀 ~ file: LopandeAccrualFilters.vue:53 ~ accountUpdated ~ accountUpdated:", this.interimAccount);
        },
        assignInitValues() {
            // const { filterEndDate } = queryString.parse(location.search);
            // const { includeCompleted } = queryString.parse(location.search);
            // this.endDate = filterEndDate || "";
            // this.includeCompleted = includeCompleted === "true" ? true : false;
        },

        inputChanged(value) {
            this.endDate = value;
            this.updateFilters();
        },

        filtersChangedImmediately() {
            clearInterval(this.timer);
            this.updateFilters();
            this.$emit("change");
        },

        async filtersChanged() {
            this.updateFilters();
            this.emitChange();
        },

        async updateFilters() {
            const query = queryString.parse(location.search);
            query.filterEndDate = this.endDate;
            query.includeCompleted = this.includeCompleted;
            await this.$router.replace({ query }).catch(() => {});
            this.$emit("change", this.endDate, this.includeCompleted, this.interimAccount);
        },

        emitChange() {
            clearInterval(this.timer);
            this.timer = setTimeout(() => {
                this.$emit("change");
            }, 1000);
        },

        clearFilters() {
            this.reset();
            this.$emit("change");
        },

        async reset() {
            this.endDate = "";
            this.includeCompleted = false;
            this.$refs.dateInputField.reset();
            const query = queryString.parse(location.search);
            delete query.filterEndDate;
            delete query.includeCompleted;
            await this.$router.replace({ query }).catch(() => {});
        },
    },
};
</script>
